<template>
  <div>
    <div class="fixed-top">
      <theheader></theheader>
    </div>
    <div class="section  py-xl-5 mt-5">
      <div class="p-xl-0 pt-2">
        <b-container>
          <subtitle
            class="deepblue"
            subtitle="2KN万能拉伸机招标公告-公开招标"
          ></subtitle>
        </b-container>
      </div>
      <div class="d-flex justify-content-center py-xl-2 gray">
        发布时间：2025-01-03
      </div>
      <div class="d-flex justify-content-center py-xl-2">
        项目名称：2KN万能拉伸机采购项目
      </div>
      <div class="p-xl-0 pt-2 tender-main">
        <b-container v-for="item in contents" :key="item.title">
          <div class="pt-xl-3 text-left tender-main-title" data-aos="fade-up">
            {{ item.title }}
          </div>
          <div class="pt-xl-1 tender-main-content text-left" data-aos="fade-up">
            {{ item.desc }}
          </div>
          <div
            v-if="item.list"
            class="pt-xl-3 tender-main-content text-left px-xl-8 px-md-5"
            data-aos="fade-up"
          >
            <b-table
              class="tender-table"
              striped
              hover
              :items="item.list"
            ></b-table>
          </div>
        </b-container>

        <b-container class="p-xl-3  text-left">
          附件：<a
            target="_blank"
            download="2KN万能拉伸机招标技术要求.pdf"
            href="https://file.sydo.com.cn/2KN%E4%B8%87%E8%83%BD%E6%8B%89%E4%BC%B8%E6%9C%BA%E6%8B%9B%E6%A0%87%E6%8A%80%E6%9C%AF%E8%A6%81%E6%B1%82.pdf"
            >2KN万能拉伸机招标技术要求</a
          >
        </b-container>
      </div>
    </div>
    <div class="section white-bg">
      <thefooter></thefooter>
    </div>
    <div class="theToggle">
      <toogle></toogle>
    </div>
  </div>
</template>

<!-- https://file.sydo.com.cn/2KN%E4%B8%87%E8%83%BD%E6%8B%89%E4%BC%B8%E6%9C%BA%E6%8A%80%E6%9C%AF%E8%A6%81%E6%B1%82.docx -->
<script>
import thefooter from '../components/elements/footer.vue';
import theheader from '../components/elements/theheader.vue';
import toogle from '../components/elements/toggleMob.vue';
import subtitle from '../components/typography/subtitle.vue';

export default {
  name: 'tender',
  components: { theheader, toogle, thefooter, subtitle },
  data() {
    return {
      contents: [
        {
          title: '投标说明',
          desc:
            '拟投标人根据重庆数元道科技有限公司官网的招标信息，邮件报名。报名邮箱：dai.sun@sydo.com.cn。邮件附件包含报价函（分项报价）以及详细技术参数，报价函单位为（人民币）元，请务必说明税率。',
        },
        {
          title: '技术要求',
          desc:
            '详细技术要求见附件。拟投标人应与招标人指派的答疑人员充分沟通，理解认可并接受相关技术规范及服务要求。答疑时间以及联系方式如下：',
          list: [
            {
              label: '答疑时间',
              desc: '截止至2025年01月10日17点前，逾期不受理',
            },
            {
              label: '答疑方式',
              desc: '电话及邮件',
            },
            { label: '联 系 人', desc: '孙岱' },
            { label: '电  话', desc: '17623821618' },
            { label: '邮  箱', desc: 'dai.sun@sydo.com.cn' },
          ],
        },
        {
          title: '投标截至时间',
          desc: '本次投标截至时间至2025年01月14日17点前。',
        },
        {
          title: '开标时间',
          desc: '本次开标时间为2025年01月15日10点，若有变动，另行通知。',
        },
        {
          title: '合同签订',
          desc:
            '招标人根据评标结果确定并通知中标人，招标人不承诺将合同授予报价最低的投标人。中标人应当按照合同约定履约责任，在保证质量的前提下完成中标项目，不得将中标项目转包或分包给他人，否则视为违约，招标人有权解除合同。合同以双方最终签署的版本为准。',
        },
      ],
    };
  },
};
</script>

<style scoped>
.live-chat {
  position: fixed;
  z-index: 999999;
}
.nav-link {
  padding: 16px;
}
.nav-link a:hover {
  background-color: #0a79ff;
}
.nav-title {
  font-weight: 300;
  font-size: 22px;
}
.nav-logo {
  width: 200px;
  height: 77.8px;
}
.tender-main {
  font-size: 22px;
  font-weight: 300;
}
.tender-main-title {
  font-weight: 500;
}
.tender-main-content {
  text-indent: 2em;
}
</style>
<style>
.tender-table thead {
  display: none !important;
}
</style>
